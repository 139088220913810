<template>
    <div>
        <AlertForDeprecate/>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">
    <b-col v-if="showVerify == true" class="content-header-left mb-2" cols="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <b-alert variant="success full-width" show>
            <div class="alert-body">
              <span>{{ $t("Navbar.Alert.success_send_email_verify") }}</span>
              <b-button class="mr-2 ml-2" type="submit" variant="primary" @click="sendEmail">
                {{ $t("Navbar.Button.send_verify_again") }}
              </b-button>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="4">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0 border-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper" v-if="$route.meta.breadcrumb">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="item in $route.meta.breadcrumb" :key="item.text" :active="item.active"
                :to="item.to">
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col md="8" cols="12">
        <b-row class="d-flex justify-content-end">
          <b-col lg="3" class="drop-width" v-show="showDates" v-if="$route.meta.showDate">
            <b-dropdown class="full-width" :text="$t(selectedItemDate)" v-model="selectedItemDate"
              variant="outline-primary">
              <b-dropdown-item class="drop-width" @click="select7">
                {{ $t("Navbar.last_seven_days") }}
              </b-dropdown-item>
              <b-dropdown-item class="drop-width" @click="select28">
                {{ $t("Navbar.28_days_ago") }}
              </b-dropdown-item>
              <b-dropdown-item @click="selectFirst">
                {{ firstMonth }}
              </b-dropdown-item>
              <b-dropdown-item @click="selectSecond">
                {{ secondMonth }}
              </b-dropdown-item>
              <b-dropdown-item @click="selectThird">
                {{ thirdMonth }}
              </b-dropdown-item>
              <b-dropdown-item @click="select365">
                {{ $t("Navbar.a_year_ago") }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col lg="3" v-show="showDates" v-if="$route.meta.showDate">
            <b-form-group>
              <flat-picker v-model="rangeDate" class="
                  form-control
                  border-primary
                  text-primary text-center
                  d-flex
                  flex-column
                  mr-2
                " :config="config" @on-change="dateChanged" :placeholder="$t('Navbar.date_filter')" dir="ltr" />
            </b-form-group>
          </b-col>
        <!-- <b-col lg="4" :class="
          $store.state.appConfig.isRTL
            ? 'text-left d-none d-lg-block'
            : 'text-right d-none d-lg-block'" v-if="$route.meta.addChannel">
          <b-img :src="
            $store.state.appConfig.layout.skin == 'dark'
              ? require('@/assets/images/banner/ytc-small-light.png')
              : require('@/assets/images/banner/ytc-small.png')
          " alt="logo" height="40" />
        </b-col> -->
        <b-col lg="3" v-if="$route.meta.addChannel">
          <b-form-group v-if="$route.meta.addChannel">
            <b-button class="px-1 py-1" type="submit" variant="primary" block v-b-modal.add-channel>
              <feather-icon icon="GlobeIcon" size="16" />
              {{ $t("Navbar.new_channel") }}
            </b-button>
          </b-form-group>
        </b-col>
        <!-- <b-col lg="2" v-if="$route.meta.addChannel">
          <b-form-group v-if="$route.meta.addChannel">
            <b-button type="submit" variant="success" block @click="goToPageReview">
              {{ $t("Navbar.Request Channel Review") }}
            </b-button>
          </b-form-group>
        </b-col> -->

        <b-col lg="4" v-if="$route.meta.btnNewTicket">
          <b-form-group v-if="$route.meta.btnNewTicket">
            <b-button class="px-0 py-1"  variant="primary" block @click="goToPageNewTicket">
              {{ $t("Menu.Send Ticket") }}
            </b-button>
          </b-form-group>
        </b-col>

        <b-col lg="4" v-if="$route.meta.addRequestToCity">
          <b-form-group v-if="$route.meta.addRequestToCity">
            <b-button class="px-0 py-1" variant="primary" block v-b-modal.modal-city>
              {{ $t("Navbar.req_to_add") }}
            </b-button>
          </b-form-group>
        </b-col>

        <b-col lg="3" v-if="$route.meta.playlist && PermissionYoutubeSSL">
          <b-form-group v-if="PermissionCreatePlaylist">
            <b-button type="submit" variant="primary" block v-b-modal.modal-login1>
              {{ $t("Navbar.new_playlist") }}
            </b-button>
          </b-form-group>
          <b-form-group v-else >
            <b-button type="submit" variant="primary" block @click="openPermissionDenid">
              {{ $t("Navbar.new_playlist") }}
            </b-button>
          </b-form-group>
        </b-col>

        <b-col lg="3" v-if="$route.meta.video && checkPermissionYoutubeSSL">
          <b-form-group v-if="PermissionUploadVideo">
            <b-button type="submit" variant="primary" block v-b-modal.modal-upload-video @click="goToPageInsertVideo">
              {{ $t("Navbar.new_video") }}
            </b-button>
          </b-form-group>
          <b-form-group v-else>
            <b-button type="submit" variant="primary" block @click="openPermissionDenid">
              {{ $t("Navbar.new_video") }}
            </b-button>
          </b-form-group>
        </b-col>

          <b-col lg="3" v-if="$route.meta.showDefaultUploadProfileModal" >
            <b-button type="submit" variant="primary" block v-b-modal.modal-upload-profile @click="" >
              {{ $t("Pages.Studio.Button.create_upload_profile") }}
            </b-button>
          </b-col>

        </b-row>



    </b-col>

  </b-row>
    </div>
</template>

<script>
import {
  BBreadcrumb,
  BImg,
  BBreadcrumbItem,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPicker from "vue-flatpickr-component";
import moment from "moment";
import AlertForDeprecate from "@/components/AlertForDeprecate.vue";

const today = new Date();
var last = new Date();
last.setDate(last.getDate() - 28);

export default {
  components: {
      AlertForDeprecate,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    flatPicker,
    BButton,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BAlert,
  },
  data() {
    return {
      showDates: true,
      rangeDate: `${this.formatDate(today)} to ${this.formatDate(last)}`,
      config: {
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
        defaultDate: `${this.formatDate(today)} to ${this.formatDate(last)}`,
      },
      selectedItemDate: "",
      dates: [],
      firstMonth: "",
      secondMonth: "",
      thirdMonth: "",
      showVerify: false,
      PermissionYoutubeSSL: false,
      PermissionCreatePlaylist:false,
      PermissionUploadVideo:false,
      permissions: [],
      count_coin:0,
      details:{}
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    getThreeMonth() {
      this.firstMonth = moment().subtract(1, "months").format("MMMM");
      this.secondMonth = moment().subtract(2, "months").format("MMMM");
      this.thirdMonth = moment().subtract(3, "months").format("MMMM");
    },
    selectFirst() {
      var firstDay = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      var lastDay = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      this.rangeDate = `${firstDay} to ${lastDay}`;
      this.selectedDates = `${firstDay} to ${lastDay}`;
      this.selectedItemDate = this.firstMonth;
    },
    selectSecond() {
      var firstDay = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      var lastDay = moment()
        .subtract(2, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      this.rangeDate = `${firstDay} to ${lastDay}`;
      this.selectedDates = `${firstDay} to ${lastDay}`;
      this.selectedItemDate = this.secondMonth;
    },
    selectThird() {
      var firstDay = moment()
        .subtract(3, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      var lastDay = moment()
        .subtract(3, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      this.rangeDate = `${firstDay} to ${lastDay}`;
      this.selectedDates = `${firstDay} to ${lastDay}`;
      this.selectedItemDate = this.thirdMonth;
    },
    select7() {
      var today = moment().format("YYYY-MM-DD");
      var last = moment().subtract(7, "days").format("YYYY-MM-DD");
      this.rangeDate = `${last} to ${today}`;
      this.selectedDates = `${last} to ${today}`;
      this.selectedItemDate = "Navbar.last_seven_days";
    },
    select28() {
      var today = moment().format("YYYY-MM-DD");
      var last = moment().subtract(28, "days").format("YYYY-MM-DD");
      this.rangeDate = `${last} to ${today}`;
      this.selectedDates = `${last} to ${today}`;
      this.selectedItemDate = "Navbar.28_days_ago";
    },
    select365() {
      var today = moment().format("YYYY-MM-DD");
      var last = moment().subtract(1, "years").format("YYYY-MM-DD");
      this.rangeDate = `${last} to ${today}`;
      this.selectedDates = `${last} to ${today}`;
      this.selectedItemDate = "Navbar.a_year_ago";
    },
    sendEmail() {
      this.$http
        .get("/api/user/verify/send")
        .then((response) => {
          if (response.data.status == true) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Navbar.Alert.success_send_email_verify"),
              "success"
            );
          }
        })
        .catch((error) => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Navbar.Alert.error_while_sending_code"),
            "danger"
          );
          if (error.response.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => { });
          }
        });
    },
    checkEmailVerify() {
      this.$http
        .get("/api/user/check/verify")
        .then((response) => {
          if (response.data.status == true) {
            this.showVerify = false;
          } else {
            this.showVerify = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => { });
          }
        });
    },
    dateChanged(selectedDates, dateStr, instance) {
      if (dateStr.includes("to")) {
        this.dates = dateStr.split(" to ");
        var get_current_url = window.location.pathname;
        if (this.$route.query.id) {
          this.$router
            .push({
              path: get_current_url,
              query: {
                id: this.$route.query.id,
                start: this.dates[0],
                end: this.dates[1],
              },
            })
            .catch(() => { });
        } else {
          this.$router
            .push({
              path: get_current_url,
              query: { start: this.dates[0], end: this.dates[1] },
            })
            .catch(() => { });
        }
      }
    },
    goToPageNewTicket(){
      this.$router.push({ name: 'ticket-new'})
    },
    goToPageReview(){
      this.$router.push({ name: 'reviewCheck'})
    },
    formatDate(date) {
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    checkPermissionYoutubeSSL() {
      this.$http
        .get(`/api/channel/studio/checkPermission`, {
          params: {
            channel_id: this.$route.params.channel_id,
          },
        })
        .then((res) => {
          if (res.data.success == true) {
            this.PermissionYoutubeSSL = true;
          } else {
            this.PermissionYoutubeSSL = false;
          }
        });
    },
    getCountCoin() {
      this.$http
        .get(`/api/dashboard/getCountCoin`)
        .then((response) => {
          this.count_coin = response.data.count_coin;
        });
    },
    getUseDetails() {
      this.$http
        .get(`/api/channel/studio/videos/use-details`)
        .then((response) => {
          this.details = response.data;
        });
    },
    goToPageInsertVideo() {
      if(this.count_coin >= 9 || this.details.create < 2){
        this.$http.get(`/api/check_login`).then(({ data }) => {
        let permissions = [];
        data.permissions.map((item) => {
          permissions.push(item.name);
        });
        if (permissions.includes("Uplaod Video")) {
          this.$root.$emit("channel_id", this.$route.params.channel_id);
          this.$root.$emit("user_id", data.user.id);
          this.$toast.clear();
        } else {
          this.$swal({
            title: this.$t("Base.Alert.Error"),
            text: this.$t("Navbar.Title.need_golden"),
            icon: 'warning',
            confirmButtonText: this.$t("Navbar.Button.upgrade_account"),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push("/pricing").catch(() => { });
            }
          })
        }
      })
      }else{
        this.$swal({
            title: this.$t("Base.Alert.Error"),
            text: this.$t("Base.Alert.not_enough_coin"),
            icon: 'warning',
            confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push({ name: 'MasterCoin' }).catch(() => { });
            }
          })
      }

    },
    openPermissionDenid(){
      this.$swal({
            title: this.$t("Base.Alert.Error"),
            text: this.$t("Navbar.Title.need_golden"),
            icon: 'warning',
            confirmButtonText: this.$t("Navbar.Button.upgrade_account"),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push("/pricing").catch(() => { });
            }
          })
    }
  },
  watch: {
    "$route.query.start": {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.rangeDate = `${this.$route.query.start} to ${this.$route.query.end}`;
          this.config.defaultDate = `${this.$route.query.start} to ${this.$route.query.end}`;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.select28();
    this.getThreeMonth();
    this.checkEmailVerify();
    this.checkPermissionYoutubeSSL();
    this.$http.get("/api/check_login").then(({ data }) => {
      let permissions = [];
      if (data.permissions.length != 0) {
        data.permissions.map((item) => {
          permissions.push(item.name);
        });
      }
      if (permissions.includes("Create PlayList")) {
        this.PermissionCreatePlaylist = true;
      }
      if (permissions.includes("Uplaod Video")) {
        this.PermissionUploadVideo = true;
      }

    });

  },
  mounted() {
    this.getUseDetails()
    this.getCountCoin()
    this.$root.$on("disableDates", (data) => {
      this.showDates = data;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.full-width {
  width: 100%;
}

.drop-width button {
  overflow: hidden;
}
</style>
