<template>
  <p class="clearfix mb-0 pb-75">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25 mr-25"
        href="https://master.tube/privacy-policy/"
        target="_blank"
      >Privacy Policy of MasterTube</b-link>
    </span>
    <span class="float-md-left d-block d-md-inline-block mt-25">
      -
    </span>
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25 mr-25"
        href="https://master.tube/terms-of-service/"
        target="_blank"
        >MasterTube Terms of Service</b-link
      >
    </span>
    <span class="float-md-left d-block d-md-inline-block mt-25"> - </span>
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25 mr-25"
        href="https://www.youtube.com/t/terms"
        target="_blank"
        >YouTube ToS</b-link
      >
    </span>
    <span class="float-md-left d-block d-md-inline-block mt-25"> - </span>
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25 mr-25"
        href="https://policies.google.com/privacy"
        target="_blank"
        >Google Privacy Policy</b-link
      >
    </span>
    <span class="float-md-left d-block d-md-inline-block mt-25"> - </span>
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25 mr-25"
        href="https://myaccount.google.com/permissions"
        target="_blank"
        >Google Security Settings</b-link
      >
    </span>
  </p>
</template> 

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>