<template>
  <ul v-if="permissions && userEmail">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in itemNav"
      :key="item.header || item.title"
      :item="item"
    />
    <li class="nav-item"></li>
  </ul>
  <ul v-else class="h-100 d-flex align-items-center justify-content-center">
    <b-overlay
      :show="true"
      spinner-variant="primary"
      variant="transparent"
      spinner-type="grow"
      spinner-small
      blur="1rem"
      rounded="sm"
    ></b-overlay>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import { BLink, BBadge, BOverlay } from "bootstrap-vue";
const yotubeAccounts = process.env.VUE_APP_YOUTUBE_ACCOUNTS;

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BLink,
    BBadge,
    BOverlay,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      permissions: [],
      userEmail: "",
    };
  },
  created() {
    this.$http.get(`/api/check_login`).then(({ data }) => {
      this.permissions = data.permissions.map((item) => item.name);
      this.userEmail = data.user.email;
    });
  },
  computed: {
    itemNav() {
      const youtubeAccountsArray = yotubeAccounts.split(",");
      const filteredItems = this.items.filter((item) => {
        if (Array.isArray(item.permission)) {
          return item.permission.some((val) => this.permissions.includes(val));
        } else {
          if (
            youtubeAccountsArray.includes(this.userEmail) &&
         (   item.route === "Accounting" ||
            item.route === "support")
          ) {
            return false;
          }
          return (
            this.permissions.includes(item.permission) ||
            item.skipCheckPermission
          );
        }
      });
      const processChildren = (items) => {
        return items.map((item) => {
          if (item.children) {
            const filteredChildren = item.children.filter((itemChild) => {
              if (Array.isArray(itemChild.permission)) {
                return itemChild.permission.some((val) =>
                  this.permissions.includes(val)
                );
              } else {
                return (
                  this.permissions.includes(itemChild.permission) ||
                  itemChild.skipCheckPermission
                );
              }
            });
            return { ...item, children: filteredChildren };
          }
          return item;
        });
      };
      return processChildren(filteredItems);
    },
  },

  methods: {
    resolveNavItemComponent,
  },
  setup() {
    provide("openGroups", ref([]));
  },
};
</script>
